import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Link from 'components/Link';
import { OutlinedButton } from 'components/v2/common/Buttons/Outlined';
import { UnderlinedButton } from 'components/v2/common/Buttons/Underlined';
import NavIconsDesktop from './NavIconsDesktop';
import { useState } from 'react';
import { HamburgerOption } from './HamburgerOption';
import podLogo from 'assets/images/pod-logo.svg';
import hamburger from 'assets/images/menu.svg';
import investMenuIcon from 'assets/images/investMenuIcon.svg';
import arrowUp from 'assets/images/arrowUp.svg';
import howItWorksIcon from 'assets/images/howItWorksIcon.svg';
import faqIcon from 'assets/images/faqIcon.svg';
import podCafeIcon from 'assets/images/podCafeIcon.svg';
import aboutIcon from 'assets/images/aboutIcon.svg';
import logoutIcon from 'assets/images/logout.svg';
import { useUser } from 'context/user.context';
import { useRouter } from 'next/router';
import { ProfileButton } from 'components/Layout/Nav/ProfileButton';

const StyledNavbar = styled('div')(({ theme }) => ({
  backgroundColor: '#FAFCF7',
  padding: 8,
  height: 72,
  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.06))',
  [theme.breakpoints.down('md')]: {
    height: 54,
  },
}));

export const Nav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [userData, logout] = useUser();
  const role_id = userData.user?.role_id;
  const userLoading = userData.isLoading;

  // const user = userData.user;

  const router = useRouter();

  const logoutUser = async () => {
    logout();
    role_id === 2 ? router.push('/raise-funds') : router.push('/');
    // setOpenDrawerHandler(false);
  };

  const noNavPaths: { [key: string]: boolean } = {
    '/register': true,
    '/login': true,
    '/we-pop': true,
    '/index2': true,
  };

  const matches = noNavPaths[router.pathname];
  return (
    <>
      <StyledNavbar sx={{ display: matches ? 'none' : 'flex' }}>
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingX: {
              xs: 1,
              md: 'inherit',
            },
          }}
        >
          <Box
            flexGrow={1}
            flexBasis={0}
            display="flex"
            alignItems="center"
            sx={{
              '& img': {
                width: {
                  xs: 50,
                },
              },
            }}
          >
            <Link href="/">
              <Box display="flex" alignItems="center">
                <img src={podLogo} alt="pod logo" />
              </Box>
            </Link>
          </Box>
          <NavIconsDesktop />
          <Box
            flexGrow={1}
            flexBasis={0}
            sx={{
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              {userLoading ? null : userData.user ? (
                // <OutlinedButton onClick={logoutUser}>Logout</OutlinedButton>
                <ProfileButton
                  sx={{
                    ml: {
                      xs: 0,
                      md: 1.5,
                    },
                    display: {
                      xs: 'inherit',
                      md: 'none',
                    },
                  }}
                  handleLogout={logoutUser}
                  image={userData.user?.profile_image}
                  name={
                    userData.user?.first_name?.[0]?.toUpperCase() +
                      userData.user?.last_name?.[0]?.toUpperCase() || ''
                  }
                  onClick={() => setMobileMenuOpen(false)}
                />
              ) : (
                <>
                  <Link href="/login">
                    <OutlinedButton sx={{ mr: '24px' }}>Login</OutlinedButton>
                  </Link>
                  <Link href="/register">
                    <UnderlinedButton>Signup</UnderlinedButton>
                  </Link>
                </>
              )}
            </Box>
          </Box>
          {!!userData.user && (
            <ProfileButton
              sx={{
                // display: {
                //   xs: 'none',
                //   md: 'inherit',
                // },

                ml: {
                  xs: 0,
                  md: 1.5,
                },
                mr: {
                  xs: 1,
                  md: 0,
                },
              }}
              handleLogout={logoutUser}
              image={userData.user?.profile_image}
              name={
                userData.user?.first_name?.[0]?.toUpperCase() +
                  userData.user?.last_name?.[0]?.toUpperCase() || ''
              }
              onClick={() => setMobileMenuOpen(false)}
            />
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              display: {
                xs: 'inherit',
                md: 'none',
              },
            }}
            onClick={() => setMobileMenuOpen((s) => !s)}
          >
            <img src={hamburger} alt="menu" />
          </Box>
        </Container>
      </StyledNavbar>

      <Box
        onClick={() => setMobileMenuOpen(false)}
        sx={{
          display: {
            xs: mobileMenuOpen ? 'block' : 'none',
            md: 'none',
          },
          backgroundColor: '#F3F9EC',
        }}
        pt="9px"
        // height={30}
      >
        {role_id == 2 ? null : (
          <HamburgerOption link="/" text="Invest" icon={investMenuIcon} />
        )}
        {role_id == 1 ? (
          <HamburgerOption link="/startups" text="Startups" icon={arrowUp} />
        ) : (
          <HamburgerOption
            link="/raise-funds"
            text="Raise Fund"
            icon={arrowUp}
          />
        )}
        {/* <HamburgerOption
          link="/we-pop"
          text="WE POP 2.0"
          icon={female}
          glow
        /> */}
        {/* <HamburgerOption
          link="https://pitchpager.com"
          text="PitchPager"
          icon={onePagerIcon}
        /> */}
        {role_id ? (
          <HamburgerOption
            link={`/${role_id == 1 ? 'investor' : 'startup'}-how-it-works`}
            text="How It works"
            icon={howItWorksIcon}
          />
        ) : null}

        <HamburgerOption link="/faqs" text="FAQs" icon={faqIcon} />
        <HamburgerOption link="/pod-cafe" text="POD Cafe" icon={podCafeIcon} />
        <HamburgerOption link="/about" text="About" icon={aboutIcon} />
        {/* <HamburgerOption link="/about" text="About" icon={aboutIcon} /> */}
        <Box height="1px" bgcolor="rgba(0, 0, 0, 0.13)" />
        <Box px={2.5} component="nav" py={1.5} display="flex">
          {userLoading ? null : userData.user ? (
            <>
              <Box
                width={22}
                height={22}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img src={logoutIcon} alt="logout" />
              </Box>
              <Typography
                fontSize={14}
                ml={2}
                component="span"
                onClick={logoutUser}
              >
                Logout
              </Typography>
            </>
          ) : (
            <>
              <Link href="/login">
                <OutlinedButton sx={{ mr: '24px' }}>Login</OutlinedButton>
              </Link>
              <Link href="/register">
                <UnderlinedButton>Signup</UnderlinedButton>
              </Link>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
